.wh-autocomplete-values {
  list-style-type: none;
  border-radius: 8px;
  margin: 0;
  padding: 15px 0;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid E4E4E4;
  z-index: 1;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.14);
}
.wh-autocomplete-values > li {
  line-height: 150%;
  cursor: pointer;
  padding: 3px 24px;
  margin: 0 15px;
  color: var(--color-black);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wh-autocomplete-values > li.noresults {
  font-style: italic;
  cursor: default;
}
.wh-autocomplete-values > li.suggestion:focus, .wh-autocomplete-values > li.suggestion:hover {
  border-radius: 8px;
  background-color: rgba(5, 114, 209, 0.1);
}
.wh-autocomplete-values > li emp, .wh-autocomplete-values > li i {
  font-weight: bold;
}