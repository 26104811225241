.footer__bar {
  background-color: var(--color-blue);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  height: 80px;
  border-radius: 8px;
}
.footer__logo {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
.footer__logo img {
  width: 179px;
  height: auto;
  vertical-align: bottom;
}
.footer__nav {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  margin: 5px 0 15px;
}
.footer__nav span, .footer__nav a {
  display: inline-block;
  color: #727272;
  margin: 0 clamp(5px, 1vw, 10px);
}
.footer__nav a {
  text-decoration: none;
  text-underline-position: under;
}
.footer__nav a:hover {
  text-decoration: underline;
}