.widget-highlight {
  border-radius: 8px;
  padding: 25px var(--content-padding);
}
@media (max-width: 750px) {
  .widget-highlight {
    padding: 15px var(--content-padding-mobile);
  }
}
.widget-highlight > *:last-child {
  margin-bottom: 0;
}
.widget-highlight--background {
  background-color: var(--color-blue-light);
}
.widget-highlight--outline {
  border: 1px solid var(--color-blue);
}