.searchpage__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.searchpage__pagination a {
  text-decoration: none;
  color: inherit;
}
.searchpage__pagination__previous, .searchpage__pagination__next {
  flex: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  background-color: var(--color-blue);
}
.searchpage__pagination__previous--disabled, .searchpage__pagination__next--disabled {
  background-color: var(--color-blue);
}
@media (max-width: 565px) {
  .searchpage__pagination__previous, .searchpage__pagination__next {
    width: 8.5vw;
    height: 8.5vw;
  }
}
@media (max-width: 375px) {
  .searchpage__pagination__previous, .searchpage__pagination__next {
    width: 32px;
    height: 32px;
  }
}
.searchpage__pagination ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchpage__pagination ul li {
  display: inline-block;
  padding: 0 5px;
}
.searchpage__pagination__page {
  font-size: 18px;
}
.searchpage__pagination__page--active {
  font-weight: 800;
}

.wh-searchresults {
  margin-top: 50px;
  margin-bottom: 70px;
}
.wh-searchresults__feedback {
  font-weight: 700;
}
.wh-searchresults .consilio--highlight {
  font-weight: 700;
}
.wh-searchresults__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.wh-searchresults__listitem {
  margin-top: 15px;
}
.wh-searchresults__listitem__text {
  flex: 1 1 auto;
  max-width: 100%;
}
.wh-searchresults__listitem__meta {
  opacity: 0.85;
  font-size: 95%;
}
.wh-searchresults__listitem__link {
  color: inherit;
  text-decoration: none;
  display: flex;
}
.wh-searchresults__listitem__title {
  font-weight: 700;
  font-size: 120%;
  display: block;
}
.wh-searchresults__listitem__url {
  color: var(--color-blue);
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-underline-position: under;
}
a:hover .wh-searchresults__listitem__url {
  text-decoration: underline;
}