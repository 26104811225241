.widget-infolinks__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-top: -30px;
}
.widget-infolinks--count1 .widget-infolinks__list {
  justify-content: center;
}
.widget-infolinks__item {
  margin-left: var(--content-padding);
  margin-top: var(--content-padding);
  flex: 1 1 50%;
  max-width: calc(50% - 30px);
  display: flex;
  flex-flow: column;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ECECEC;
  box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.14);
  padding: 25px 30px;
}
.widget-infolinks__item__title {
  flex: none;
  hyphens: auto;
  font-family: var(--font-heading);
}
.widget-infolinks__item__description {
  flex: 1 1 auto;
  line-height: 165%;
}
.widget-infolinks__item__description > *:last-child {
  margin-bottom: 0;
}
.widget-infolinks__item__link {
  font-family: var(--font-heading);
  flex: none;
  display: inline-flex;
  color: var(--color-blue-link);
  font-weight: 600;
  margin-top: 20px;
  line-height: 150%;
  align-items: baseline;
  text-decoration: none;
}
.widget-infolinks__item__link span {
  text-underline-position: under;
}
.widget-infolinks__item__link:hover > span {
  text-decoration: underline;
}
.widget-infolinks__item__link i {
  display: inline-block;
  flex: none;
  margin-right: 15px;
  position: relative;
  top: 1px;
}
.widget-infolinks.basegrid__wide .widget-infolinks__item {
  flex: 1 1 33.3333%;
  max-width: calc(33.3333% - 30px);
}
@media (max-width: 900px) {
  .widget-infolinks.basegrid__wide .widget-infolinks__item {
    flex-basis: 50%;
    max-width: calc(50% - 30px);
    margin-top: var(--content-padding);
  }
}
@media (max-width: 750px) {
  .widget-infolinks__list {
    margin-left: -20px;
  }
  .widget-infolinks__item, .widget-infolinks.basegrid__wide .widget-infolinks__item {
    margin-left: var(--content-padding-mobile);
    padding: var(--content-padding-mobile);
    max-width: calc(50% - 20px);
  }
}
@media (max-width: 550px) {
  .widget-infolinks__list {
    margin-left: 0;
    display: block;
  }
  .widget-infolinks__item, .widget-infolinks.basegrid__wide .widget-infolinks__item {
    margin-left: 0;
    max-width: 100% !important;
  }
}