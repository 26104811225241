body {
  padding-top: 120px;
}
html.searchpage body {
  padding-top: 240px;
}
@media (max-width: 750px) {
  body {
    padding-top: 56px;
  }
  html.searchpage body {
    padding-top: 136px;
  }
}
html.showmenu body::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 41, 59, 0.5);
  z-index: 1;
}

header {
  height: 120px;
  font-family: var(--font-heading);
  z-index: 10;
  background-color: #fff;
  color: #6a6a6a;
  border-bottom: 1px solid #d6d6d6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 300ms;
}
html.showmenu header {
  background-color: var(--color-blue-button);
  color: #fff;
  border-bottom-color: var(--color-blue-button);
}
html.stickyheader header {
  position: fixed;
  transition: background-color 300ms, transform 300ms;
  transform: translateY(0);
}
html.allowsticky:not(.stickyheader):not(.stickyheader--animate):not(.showmenu) header {
  position: fixed;
  transform: translateY(-120px);
}
html.stickyheader--animate header {
  position: fixed;
  transform: translateY(-120px);
}
html.showmenu header {
  position: fixed;
  transform: translateY(0);
}
@media (max-width: 750px) {
  header {
    height: 56px;
    background-color: var(--color-blue-button);
    color: #fff;
    border-bottom-color: var(--color-blue-button);
  }
  html.allowsticky:not(.stickyheader):not(.stickyheader--animate):not(.showmenu) header {
    transform: translateY(-56px);
  }
  html.stickyheader--animate header {
    transform: translateY(-56px);
  }
}

.header a {
  color: inherit;
  text-decoration: none;
}
.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__menutoggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  font-size: 30px;
  width: 30px;
  margin-right: 50px;
}
.header__menutoggle__icon-hide {
  display: none;
}
html.showmenu .header__menutoggle__icon-show {
  display: none;
}
html.showmenu .header__menutoggle__icon-hide {
  display: inline-block;
}
@media (max-width: 750px) {
  .header__menutoggle {
    font-size: 22px;
  }
}
.header__logo {
  flex: none;
  display: inline-block;
  position: relative;
}
.header__logo img {
  width: 283px;
  height: auto;
  vertical-align: bottom;
}
@media (max-width: 750px) {
  .header__logo img {
    width: 129px;
  }
}
.header__logo__blue {
  transition: opacity 300ms;
  opacity: 1;
}
html.showmenu .header__logo__blue {
  opacity: 0;
}
@media (max-width: 750px) {
  .header__logo__blue {
    opacity: 0;
  }
}
.header__logo__white {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms;
  opacity: 0;
}
html.showmenu .header__logo__white {
  opacity: 1;
}
@media (max-width: 750px) {
  .header__logo__white {
    opacity: 1;
  }
}
.header__outfiller {
  flex: 1 1 auto;
}
.header__searchpanel-toggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 30px;
}
@media (max-width: 750px) {
  .header__searchpanel-toggle {
    font-size: 22px;
  }
}

.header__mainnav {
  overflow: hidden;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  width: 390px;
  max-width: 100%;
  transition: width 300ms;
  padding-top: 120px;
  font-family: var(--font-heading);
  font-weight: 600;
  line-height: 150%;
}
html.searchpage .header__mainnav {
  padding-top: 240px;
}
@media (max-width: 750px) {
  .header__mainnav {
    padding-top: 56px;
  }
  html.searchpage .header__mainnav {
    padding-top: 136px;
  }
}
.header__mainnav[hidden] {
  display: block;
  width: 0;
}
.header__mainnav__inner {
  width: 390px;
  max-width: 100vw;
  padding: 20px 10px 20px 20px;
  height: 100%;
}
.header__mainnav__inner > ul {
  max-height: 100%;
  padding-right: 10px;
}
.header__mainnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.header__mainnav ul li:not(:last-child) {
  padding-bottom: 7px;
}
.header__mainnav ul ul {
  padding-left: 15px;
}
.header__mainnav ul ul li:first-child {
  padding-top: 7px;
}
.header__mainnav__item {
  margin: 10px 0;
}
.header__mainnav__item__link {
  display: flex;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  justify-content: space-between;
  color: inherit;
  font: inherit;
  text-decoration: none;
  transition: color 300ms;
}
.header__mainnav__item__link:hover {
  color: var(--color-blue-link);
}
.header__mainnav__item .header__mainnav__item {
  margin: 5px 0;
}
.header__mainnav__item .header__mainnav__item .header__mainnav__item__link {
  font-weight: 400;
  font-size: 15px;
}
.header__mainnav__toggle {
  color: var(--color-blue-link);
  flex: none;
  text-align: right;
  margin-top: 8px;
  margin-right: 8px;
  width: 30px;
  transform-origin: center;
}
.header__mainnav__item--active > .header__mainnav__item__link, .wh-details--open > .header__mainnav__item__link {
  color: var(--color-blue-link);
}
.wh-details--open > .header__mainnav__item__link > .header__mainnav__toggle {
  margin-top: 0;
  margin-right: 0;
  transform: rotate(90deg);
}

.header__search {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: var(--color-blue);
}
.header__search__panel {
  position: relative;
  height: 120px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms;
  display: flex;
  align-items: center;
}
.header__search__panel[hidden] {
  visibility: hidden;
}
.header__search--beforehide .header__search__panel[hidden] {
  visibility: visible;
}
.header__search--active .header__search__panel {
  max-height: 120px;
  overflow: visible;
}
.header__search__right {
  grid-area: 1/content-end/2/xwide-end;
}
.header__search--active .header__search__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__search__hidepanel {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  color: #fff;
  font-size: 24px;
}
.header__search__form {
  flex: none;
  width: 100%;
  background-color: #fff;
  height: 50px;
  overflow: visible;
  border-radius: 100px;
  display: flex;
  position: relative;
  padding: 10px var(--content-padding);
}
.header__search__form .wh-autocomplete-values {
  margin-top: 5px;
}
.header__search__form__submit {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.header__search__form__input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0 none;
  flex: 1 1 auto;
  height: 30px;
  min-width: 100px;
  line-height: 30px;
  font-size: 18px;
  color: #333;
  margin-right: 15px;
}
html.searchpage .header__search__panel {
  max-height: 120px;
  overflow: visible;
}
@media (max-width: 750px) {
  .header__search {
    padding: 0 var(--content-padding-mobile);
    border-top: 1px solid #fff;
  }
  .header__search__panel {
    height: 80px;
    grid-area: 1/content-start/2/content-end !important;
  }
  .header__search__right {
    grid-area: 1/xwide-end/2/fullwidth-end !important;
  }
  .header__search--active .header__search__panel {
    max-height: 80px;
  }
  .header__search__form {
    height: 40px;
    padding: 5px var(--content-padding-mobile);
  }
  html.searchpage .header__search__panel {
    max-height: 80px;
  }
}
@media (max-width: 340px) {
  .header__search__panel form {
    max-width: calc(100% - 20px);
  }
  .header__search__right {
    position: relative;
  }
  .header__search__right button {
    position: absolute !important;
    right: 100%;
  }
}