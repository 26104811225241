.widget-faq--background {
  background-color: var(--color-blue-light);
  padding: 30px 0 60px;
}
.widget-faq > h2 {
  margin-bottom: 0;
}
.widget-faq__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  margin-top: 25px;
}
.widget-faq--background .widget-faq__list, .widget-faq--outline .widget-faq__list {
  border-radius: 8px;
  padding: 0 var(--content-padding);
}
@media (max-width: 750px) {
  .widget-faq--background .widget-faq__list, .widget-faq--outline .widget-faq__list {
    padding: 0 var(--content-padding-mobile);
  }
}
.widget-faq--outline .widget-faq__list {
  border: 1px solid var(--color-blue);
}
.widget-faq__item + .widget-faq__item {
  border-top: 1px solid var(--color-blue);
}
.widget-faq .wh-details__container__inner {
  padding: 0 0 var(--content-padding);
}
.widget-faq .wh-details__summary {
  padding: 22px 50px 20px 0;
  font-size: 22px;
  font-size: clamp(16px, 1.8vw, 18px);
}
.widget-faq .wh-details__summary h3 {
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
}
.widget-faq .wh-details__summary::after {
  position: absolute;
  display: block !important;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  color: var(--color-blue);
  content: "\f067";
}
.widget-faq .wh-details--open > .wh-details__summary::after {
  content: "\f068";
}