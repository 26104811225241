.widget-downloads {
  margin-bottom: 50px;
}
.widget-downloads h2 {
  text-align: center;
}
.widget-downloads__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--color-gray-lines);
}
.widget-downloads__item {
  border-bottom: 1px solid var(--color-gray-lines);
}
.widget-downloads__item__link {
  color: var(--color-blue-link);
  padding: 20px 0 19px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
}
.widget-downloads__item__icon {
  flex: none;
  width: 40px;
}
.widget-downloads__item__meta {
  font-size: clamp(15px, 1.7vw, 17px);
  color: #727272;
}
.widget-downloads__item__description {
  font-size: 14px;
  color: #727272;
}
.widget-downloads__item__title {
  line-height: 150%;
  padding-top: 4px;
  flex: 1 1 auto;
}