/* Most of the basic video styling is loaded by shared/rtd/rtd.es, @mod-publisher/js/richcontent/all */
.wh-video__innerframe {
  background-color: #000;
}
.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 94px;
  color: #fff;
  background-color: var(--color-blue-link);
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}
@media (max-width: 450px) {
  .wh-video__playbutton {
    width: 20vw;
    height: 20vw;
    font-size: clamp(20px, 6.666vw, 30px);
  }
}
.wh-video__playbutton::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f04b";
  font-weight: 700;
  margin-left: 10%;
}
.wh-video .wh-video__innerframe__preview img {
  transition: transform 0.3s;
}
.wh-video:hover .wh-video__innerframe__preview img {
  transform: scale(1.05);
}