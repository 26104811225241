@import "/.publisher/sd/hovi_database/hovisite/fonts/fontawesome/css/all.min.css";
@import "/.publisher/sd/hovi_database/hovisite/fonts/inter/inter.css";
@import "/.publisher/sd/hovi_database/hovisite/fonts/poppins/poppins.css";

html
{
  font: 16px 'Inter', Arial, Helvetica, sans-serif;
  line-height: 200%;
  color: var(--color-black);
  font-size: clamp(15px, 1.6vw, 16px);
}

h1, h2, h3, h4
{
  font-family: 'Poppins', sans-serif, serif, Arial;
  color: inherit;
}
h1
{
  font-size: clamp(24px, 4.8vw, 48px);
  line-height: 130%;
  margin-bottom: 20px;
}
h2
{
  font-size: clamp(19px, 2.8vw, 28px);
  line-height: 150%;
  color: var(--color-blue);
  margin-bottom: 15px;
}
h3, h2.heading3
{
  font-size: clamp(16px, 2.2vw, 22px);
  line-height: 145%;
  color: var(--color-blue);
  margin-bottom: 15px;
}
h4, h3.heading4
{
  font-size: clamp(16px, 1.8vw, 18px);
  line-height: 145%;
  color: var(--color-black);
  margin-bottom: 10px;
}

p a, ul a, ol a, table a, form a
{
  color: #2d6591;
}

p, .widget
{
  margin-bottom: 30px;
}

p.intro
{
  font-size: clamp(16px, 1.8vw, 18px);
  line-height: 200%;
}

.caption
{
  font-size: clamp(13px, 1.4vw, 14px);
  line-height: 145%;
  color: #727272;
}

.widget + p.caption
{
  margin-top: -15px;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
, ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  line-height: 160%;
  list-style-type: none;
}

  ul.unordered
, ol.ordered
{
  padding-right: 0;
  padding-left: 0;
  margin: 0 0 30px;
}

  p + ul.unordered
, p + ul.ordered
{
  margin-top: -20px;
}

  ul.unordered li
, ol.ordered li
{
  position: relative;
  padding: 0 0 10px 20px;
  min-height: 15px;
}
  ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
  ul.unordered li::before
, ol.ordered li::before
{
  color: #000;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
  ul.unordered > li
, ol.ordered ul > li
, ul.unordered ul > li
{
  padding-left: 20px;
  padding-bottom: 5px;
  min-height: 15px;
}
  ul.unordered > li::before
, ol.ordered ul > li::before
, ul.unordered ul > li::before
{
  content: '•';
  text-align: center;
}
  ol.ordered
, ul.unordered ol
, ol.ordered ol
{
  counter-reset: li;
}
  ol.ordered > li:before
, ul.unordered ol > li:before
, ol.ordered ol > li:before
{
  content: counter(li) ".";
  counter-increment: li;
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}

.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #1a7dd7;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #1a7dd7;
}
