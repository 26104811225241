.widget-pagethumbs__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-top: -30px;
}
.widget-pagethumbs--count1 .widget-pagethumbs__list {
  justify-content: center;
}
.widget-pagethumbs__item {
  padding-left: var(--content-padding);
  margin-top: var(--content-padding);
  flex: 1 1 50%;
  max-width: 50%;
}
.widget-pagethumbs__item__link {
  display: flex;
  flex-flow: column;
  text-decoration: none;
  color: inherit;
  height: 100%;
  background-color: #fff;
}
.widget-pagethumbs__item__image {
  border-radius: 8px;
  margin-bottom: 20px;
  flex: none;
}
.widget-pagethumbs__item__image img {
  vertical-align: bottom;
  border-radius: 8px;
  width: 100%;
  height: auto;
}
.widget-pagethumbs__item__title {
  flex: none;
  hyphens: auto;
  font-family: var(--font-heading);
}
.widget-pagethumbs__item__description {
  flex: 1 1 auto;
  font-size: clamp(15px, 1.6vw, 16px);
  line-height: 165%;
}
.widget-pagethumbs__item__linktitle {
  flex: none;
  display: inline-flex;
  color: var(--color-blue-link);
  font-weight: 600;
  margin-top: 20px;
  line-height: 150%;
  align-items: baseline;
  font-family: var(--font-heading);
}
.widget-pagethumbs__item__linktitle span {
  text-underline-position: under;
}
.widget-pagethumbs__item__linktitle i {
  display: inline-block;
  flex: none;
  margin-right: 15px;
  position: relative;
  top: 1px;
}
.widget-pagethumbs__item:hover .widget-pagethumbs__item__linktitle span {
  text-decoration: underline;
}
.widget-pagethumbs.basegrid__wide .widget-pagethumbs__item {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
}
@media (max-width: 900px) {
  .widget-pagethumbs.basegrid__wide .widget-pagethumbs__item {
    flex-basis: 50%;
    max-width: 50%;
    margin-top: var(--content-padding);
  }
}
@media (max-width: 750px) {
  .widget-pagethumbs__list {
    margin-left: -20px;
    margin-top: -20px;
  }
  .widget-pagethumbs__item {
    padding-left: var(--content-padding-mobile);
  }
}
@media (max-width: 550px) {
  .widget-pagethumbs__list {
    margin-left: 0;
    display: block;
  }
  .widget-pagethumbs__item, .widget-pagethumbs.basegrid__wide .widget-pagethumbs__item {
    padding-left: 0;
    max-width: 100%;
  }
}