:root, .wh-rtd-editor
{
  --color-black: #333; /* default text color */
  --color-blue: #098ECB;
  --color-blue-button: #0099E0;
  --color-blue-light: #E3F4FC;
  --color-blue-link: #007DB8;

  --color-magenta: #C7077A;

  --color-gray-dark: #202020;
  --color-gray-lines: #BCBCBC;
  --color-gray: #C5C5C5;

 /* forms  */
  --color-red: #D8020A;
  --color-grey-placeholder: #737373;

  --font-heading: 'Poppins', sans-serif, serif, Arial;
  --font-default: 'Inter', Arial, Helvetica, sans-serif;
}

:root
{
  --content-width-xwide: 1588px;
  --content-width-wide: 1320px;
  --content-width-medium: 745px;
  --content-width-small: 630px;

  --content-padding: 30px;
  --content-padding-mobile: 20px;
}
