.fieldsperproducttype th, .fieldsperproducttype td {
  text-align: left;
  vertical-align: top;
}
.fieldsperproducttype .subfields td {
  font-size: 95%;
  padding-bottom: 3px;
  opacity: 0.8;
}
.fieldsperproducttype .subfields td:first-child {
  padding-left: 10px;
}
.fieldsperproducttype .subfields td:first-child::before {
  content: "-";
  display: inline-block;
  padding-right: 5px;
}

.datamodel a {
  text-decoration: none;
}
.datamodel a:hover {
  text-decoration: underline;
}

.datamodel-tabledetails .debugdata {
  color: #666;
}
.datamodel-tabledetails a {
  color: var(--color-blue-link);
}
.datamodel-tabledetails .wh-details__summary {
  font-size: 15px;
  padding: 8px 50px 8px 10px;
}
.datamodel-tabledetails .wh-details__summary::after, .datamodel-tabledetails .wh-details__summary::before {
  margin-top: -10px;
  right: 15px;
}
.datamodel-tabledetails .wh-details__container__inner {
  padding: 5px 0 20px 35px;
  font-size: 15px;
}
.datamodel-tabledetails p {
  margin-bottom: 0;
}
.datamodel-tabledetails p + .typedescription {
  margin-top: 10px;
}
.datamodel-tabledetails .typedescription {
  font-size: 14px;
}
.datamodel-tabledetails .typedescription .wh-details__summary {
  background-color: #f6f6f6;
}
.datamodel-tabledetails__fields {
  margin-top: 20px;
}
.datamodel-tabledetails__fields__field {
  padding-bottom: 15px;
  padding-left: 35px;
  hyphens: auto;
}
.datamodel-tabledetails__fields__field > .tablefield {
  margin-left: -35px;
}
.datamodel-tabledetails__fields .tablefield {
  font-family: "Montserrat", sans-serif, serif, Arial;
  margin-bottom: 5px;
  line-height: 140%;
  font-weight: bold;
  font-size: 15px;
  padding: 8px 50px 8px 35px;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #f0fff8;
}
.datamodel-tabledetails__fields .tablefield__notes {
  position: absolute;
  left: 10px;
  top: 8px;
  font-size: 13px;
}
.datamodel-tabledetails__fields .tablefield__name {
  flex: 1 1 100%;
}
.datamodel-tabledetails__fields .tablefield__name i {
  font-size: 12px;
  margin-left: 7px;
}
.datamodel-tabledetails__fields .tablefield__name span.fieldtag {
  margin-left: 5px;
  background-color: #ddd;
  border-radius: 3px;
  padding: 0 3px;
  display: inline-block;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
}
.datamodel-tabledetails__fields .tablefield__type {
  font-weight: normal;
  margin-left: 15px;
  min-width: 130px;
}
.datamodel-tabledetails__fields .tablefield > div {
  white-space: nowrap;
}
.datamodel-tabledetails h2 {
  margin-top: 30px;
}
.datamodel-tabledetails dl {
  padding-bottom: 10px;
  border-bottom: 1px solid #aaa;
}
.datamodel-tabledetails dd {
  flex: 1 1 calc(100% - 115px);
}
.datamodel-tabledetails dt {
  max-width: 115px;
}
.datamodel-tabledetails .anchorhint {
  position: relative;
}
.datamodel-tabledetails .anchorhint__hitarea {
  opacity: 0;
  display: inline-block;
  transition: opacity 0.2s;
  position: absolute;
  right: 100%;
  top: 0;
  padding: 10px 5px;
  width: 30px;
  height: 40px;
  font-size: 12px;
  cursor: pointer;
}
.datamodel-tabledetails .anchorhint:hover .anchorhint__hitarea {
  opacity: 1;
}
.datamodel-tabledetails table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
}
.datamodel-tabledetails table table {
  font-size: 90%;
}
.datamodel-tabledetails th, .datamodel-tabledetails td {
  vertical-align: top;
  text-align: left;
}
.datamodel-tabledetails th + .datamodel-tabledetails th, .datamodel-tabledetails th + .datamodel-tabledetails td, .datamodel-tabledetails td + .datamodel-tabledetails th, .datamodel-tabledetails td + .datamodel-tabledetails td {
  padding: 0 5px;
}
.datamodel-tabledetails__domainvalues {
  margin-bottom: 20px;
}
.datamodel-tabledetails__domainvalues td, .datamodel-tabledetails__domainvalues th {
  font-size: 15px;
  padding: 5px 10px;
}
.datamodel-tabledetails__domainvalues tbody tr:nth-child(2n+1) td {
  background-color: #f9f9f9;
}

.relationschema {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.relationschema-wrapper {
  max-width: 1110px;
  overflow-x: auto;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1110px) {
  .relationschema-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media print {
  .relationschema-wrapper {
    overflow-x: visible;
  }
}
.relationschema__col {
  position: relative;
}
.relationschema__col + .relationschema__col {
  margin-left: 80px;
}
.relationschema .line {
  position: absolute;
  height: 1px;
  width: 10px;
  border-bottom: 2px solid #333;
  margin-top: -1px;
  transform-origin: top left;
}
.relationschema .line--array {
  opacity: 0.5;
  border-bottom-width: 1px;
}
.relationschema .line.indentright.indentright, .relationschema .line.indentleft.indentright {
  margin-left: 20px;
}
.relationschema .line.indentright.indentleft, .relationschema .line.indentleft.indentleft {
  margin-left: -20px;
}
.relationschema .line.indentright::before, .relationschema .line.indentright::after, .relationschema .line.indentleft::before, .relationschema .line.indentleft::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 20px;
  border-left: 2px solid #333;
}
.relationschema .line.indentright::after, .relationschema .line.indentleft::after {
  left: 100%;
}
.relationschema .line.indentright.domainabove.indentright::before, .relationschema .line.indentright.domainabove.indentright::after, .relationschema .line.indentleft.domainabove.indentright::before, .relationschema .line.indentleft.domainabove.indentright::after {
  transform-origin: top left;
  transform: rotate(180deg);
}
.relationschema .line.indentright.domainabove.indentright::before, .relationschema .line.indentleft.domainabove.indentright::before {
  margin-top: 2px;
}
.relationschema .dot {
  position: absolute;
  height: 6px;
  width: 6px;
  margin-top: -3px;
  margin-left: -3px;
  background-color: #333;
  transform: rotate(45deg);
}
.relationschema .dot--array {
  opacity: 0.5;
}
.relationschema .linegroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.relationschema .linegroup .line.indentright ~ .line.indentright {
  margin-left: 40px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright::before, .relationschema .linegroup .line.indentright ~ .line.indentright::after {
  height: 40px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright {
  margin-left: 60px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright::before, .relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright::after {
  height: 60px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright {
  margin-left: 80px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright::before, .relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright::after {
  height: 80px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright {
  margin-left: 100px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright::before, .relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright::after {
  height: 100px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright {
  margin-left: 120px;
}
.relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright::before, .relationschema .linegroup .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright ~ .line.indentright::after {
  height: 120px;
}

.relationschema-table {
  border-collapse: collapse;
  border: 1px solid #000;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  width: 100%;
}
.relationschema-table .arrayfield {
  background-color: #f9f9f9;
  font-size: 12px;
  margin-left: -10px;
  padding: 2px 20px 2px 20px;
  margin-right: -20px;
  position: relative;
}
.relationschema-table .arrayfield > .arrayfield {
  font-size: 90%;
}
.relationschema-table .arrayfield[data-domain] {
  color: var(--color-blue);
}
.relationschema-table .reltype {
  font-size: 9px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 5px;
}
.relationschema-table th {
  color: #fff;
  background-color: var(--color-blue);
}
.relationschema-table th a {
  color: inherit;
  display: block;
  text-decoration: none;
}
.relationschema-table td[data-domain] {
  background-color: #f0fff8;
}
.relationschema-table th, .relationschema-table td {
  vertical-align: top;
  text-align: left;
  padding: 2px 10px;
  position: relative;
  cursor: pointer;
}
.relationschema-table td {
  font-size: 15px;
  padding-right: 20px;
}
.relationschema-table .primarykey {
  background-color: #ddd;
}

.arrayreftables ul, .unreferencedtables ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 10px 0 30px -20px;
}
.arrayreftables ul li, .unreferencedtables ul li {
  padding-left: 20px;
}