
/*
Buttons:
  .button ( default solid black )
  .button--outline
  .button--text

example
  <a href="#" class="button button--outline">
    <i class="button__icon fas fa-chevron-left"></i>
    <span class="button__label">Sign up</span>
  </a>
*/

.wh-form__buttongroup
{
  text-align: right;
  padding: 30px 0;
  margin-top: 30px;
  border-top: 1px solid var(--color-blue);
}

.wh-form__buttongroup::after
{
  content: "";
  display: block;
  clear: both;
}

.wh-form__buttongroup .wh-form__button, .button
{
  margin-bottom: 10px;
}

  .wh-form__buttongroup .wh-form__button + .wh-form__button
, .wh-form__buttongroup .button + .button
{
  margin-left: 15px;
}

.button, .wh-form__button
{
  outline: none;
  -webkit-appearance: none;
  position: relative;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-decoration: none;
  text-underline-position: under;

  font: 700 19px/24px var(--font-heading);
  min-width: 120px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 50px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 10px 25px 10px 20px;

  background-color: var(--color-blue);
  color: #fff;
  text-align: left;
  transition: background-color 0.3s;
  box-shadow: none;
  margin: 0;
  border: 0 none;
}

.button--allowwrap, .wh-form__button--allowwrap
{
  height: auto;
  min-height: 50px;
  white-space: normal;
  hyphens: auto;
}

.button[disabled], .wh-form__button[disabled]
{
  pointer-events: none; //ignore hover too
}

.button:hover, .wh-form__button:hover
{
  background-color: var(--color-blue-link);

  .button__label
  {
    text-decoration-color: var(--color-blue);
    text-decoration: underline;
  }
}

.button:hover .button__label, .wh-form__button:hover .button__label
{
  text-decoration-color: var(--color-blue);
  text-decoration: underline;
}

.button__icon, .wh-form__button__icon
{
  display: inline-flex;
  flex: none;
  align-items: center;
  height: 100%;
  font-size: 18px;
}

.button__icon:first-child, .wh-form__button__icon:first-child
{
  margin-right: 15px;
}

  .buttonlabel, .wh-form__buttonlabel
, .button__label, .wh-form__button__label
{
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  flex: 1 1 auto;
}

.button__label:last-child, .wh-form__button__label:last-child
{
  text-align: center;
}

  .button__icon + .button__label
, .wh-form__button__icon + .wh-form__button__label
{
  text-align: left;
}

  .button__label + .button__icon
, .buttonlabel + .button__icon
, .wh-form__button__label + .wh-form__button__icon
, .wh-form__buttonlabel + .button__icon
{
  margin-left: 15px;
}

@media(max-width: 650px)
{
    .button__label + .button__icon
  , .buttonlabel + .button__icon
  , .wh-form__button__label + .wh-form__button__icon
  , .wh-form__buttonlabel + .button__icon
  {
    margin-left: 10px;
  }
}

.button--outline, .wh-form__button--outline
{
  background-color: transparent;
  color: var(--color-blue);
  border: 2px solid var(--color-blue);
  transition: border-color 0.2s;
}

.button--outline:hover, .wh-form__button--outline:hover
{
  background-color: transparent;
}

.button--outline:hover .button__label, .wh-form__button--outline:hover .button__label
{
  color: var(--color-blue-link);
}

  .button--outline .button__icon:first-child
, .wh-form__button--outline .wh-form__button__icon:first-child
{
  padding-right: 0;
}

.button--text, .wh-form__button--text
{
  min-width: 0;
  color: var(--color-blue);
  justify-content: flex-start;
  background-color: transparent;
  align-items: baseline;
  min-height: 27px;
  border-radius: 0;
  white-space: normal;
  height: auto;
  padding: 0;
}

.button--text:hover, .wh-form__button--text:hover
{
  background-color: transparent;
  color: var(--color-blue-link);
}

  .wh-form__button--previous, .button--previous
, .wh-form__button--cancel, .button--cancel
{
  float: left;
  color: var(--color-blue);
}

.wh-form__button--cancel, .button--cancel
{
  float: none;
  vertical-align: bottom;
  min-width: 80px;
}
