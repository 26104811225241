@charset "UTF-8";
@import "./shared/config-vars.css";
html {
  background-color: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
}

.fa-twitter::before {
  content: "𝕏" !important;
  font-size: 120%;
  font-weight: 700;
  font-family: Arial-black;
}

body {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  width: 100vw;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
html.hidescroll body {
  overflow: hidden;
}

header, footer {
  flex: none;
}

.wh-anchor {
  position: absolute;
  scroll-margin-top: 120px;
}
@media (max-width: 750px) {
  .wh-anchor {
    scroll-margin-top: 56px;
  }
}

.basegrid {
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: [fullwidth-start] calc(50% - 670px) [xwide-start] 115px [wide-start] 190px [content-start] 730px [content-end] 190px [wide-end] 115px [xwide-end] calc(50% - 670px) [fullwidth-end];
}
@media (max-width: 1440px) {
  .basegrid {
    grid-template-columns: [fullwidth-start] 5fr [xwide-start] 11.5fr [wide-start] 19fr [content-start] 73fr [content-end] 19fr [wide-end] 11.5fr [xwide-end] 5fr [fullwidth-end];
  }
}
@media (max-width: 950px) {
  .basegrid {
    grid-template-columns: [fullwidth-start] 5fr [xwide-start] 11.5fr [wide-start] 0fr [content-start] 111fr [content-end] 0fr [wide-end] 11.5fr [xwide-end] 5fr [fullwidth-end];
  }
}
@media (max-width: 567px) {
  .basegrid {
    grid-template-columns: [fullwidth-start] 20px [xwide-start] 11.5fr [wide-start] 19fr [content-start] 73fr [content-end] 19fr [wide-end] 11.5fr [xwide-end] 20px [fullwidth-end];
  }
}
.basegrid > * {
  grid-area: auto/content-start/auto/content-end;
}
@media (max-width: 750px) {
  .basegrid > *:not(.fullwidth) {
    grid-area: auto/xwide-start/auto/xwide-end;
  }
}
.basegrid > *.basegrid__xwide {
  grid-area: auto/xwide-start/auto/xwide-end;
}
.basegrid > *.basegrid__wide {
  grid-area: auto/wide-start/auto/wide-end;
}
@media (max-width: 1110px) {
  .basegrid > *.basegrid__wide {
    grid-area: auto/xwide-start/auto/xwide-end;
  }
}
.basegrid > *.basegrid__fullwidth {
  grid-area: auto/fullwidth-start/auto/fullwidth-end;
}

main {
  flex: 1 0 auto; /* vertical (column) outfill */
  max-width: 100%;
}

article {
  margin-top: 35px;
  margin-bottom: 50px;
}

.introheader {
  background-color: var(--color-blue-light);
  margin-top: -35px;
  padding: 35px 0;
  margin-bottom: 60px;
}
@media (max-width: 750px) {
  .introheader {
    padding: 25px 0;
  }
}
.introheader > .basegrid__xwide {
  grid-area: auto/xwide-start/auto/wide-end;
  display: flex;
}
@media (max-width: 1110px) {
  .introheader > .basegrid__xwide {
    grid-area: auto/xwide-start/auto/xwide-end;
  }
}
.introheader__leftcol {
  margin-right: var(--content-padding);
}
.introheader__leftcol > *:last-child {
  margin-bottom: 0;
}
.introheader__rightcol {
  flex: none;
  width: 350px;
}
@media (max-width: 1440px) {
  .introheader__rightcol {
    max-width: calc(33.3333% - 50px);
  }
}
@media (max-width: 1110px) {
  .introheader__rightcol {
    max-width: calc(33.3333% - 20px);
  }
}
@media (max-width: 900px) {
  .introheader__rightcol {
    max-width: 50%;
  }
}
.introheader__image {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 8px;
}
.introheader__cta {
  background-color: var(--color-magenta);
  color: #fff;
  border-radius: 8px;
  padding: var(--content-padding);
  font-family: var(--font-heading);
}
@media (max-width: 750px) {
  .introheader__cta {
    padding: var(--content-padding-mobile);
  }
}
.introheader__cta h2 {
  color: inherit;
}
.introheader__cta p {
  font-size: clamp(15px, 1.7vw, 17px);
}
.introheader__cta a {
  color: inherit;
  display: inline-flex;
  text-decoration: none;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 600;
  text-underline-position: under;
}
.introheader__cta a:hover > span {
  text-decoration: underline;
}
.introheader__cta a i {
  flex: none;
  display: inline-block;
  margin-right: 15px;
  line-height: 20px;
  position: relative;
  top: 6px;
}
@media (max-width: 620px) {
  .introheader > .basegrid__xwide {
    flex-flow: column;
    align-items: center;
  }
  .introheader__leftcol {
    margin-right: 0;
  }
  .introheader__rightcol {
    margin-top: 40px;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .introheader__image {
    width: 100%;
    max-width: 350px;
  }
}

.structuredbreadcrumb-wrapper ~ article .introheader {
  padding-top: 10px;
}

dl {
  font-size: 15px;
  line-height: 25px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: 0 0 20px;
  padding: 0;
}
dl dt, dl dd {
  margin: 0;
  padding: 10px 0 0;
  border-bottom: 1px solid #f1f1f1;
}
dl dt, dl dd {
  border-bottom: 0 none;
}
dl dt {
  font-weight: 700;
  flex: 1 1 33.3333%;
  min-width: 115px;
}
dl dd {
  flex: 1 1 66.6666%;
}
dl dd {
  display: flex;
  justify-content: space-between;
}

/* structuredbreadcrumb */
.bglightblue {
  background-color: var(--color-blue-light);
}

.structuredbreadcrumb {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  max-width: 100%;
  color: #767676;
}
.structuredbreadcrumb > li[hidden] + li::before {
  font-family: "Font Awesome 5 Pro" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: ".../";
  font-weight: 300;
  padding-right: 7px;
  display: inline-block;
}
.structuredbreadcrumb li {
  display: inline-block;
}
.structuredbreadcrumb li[hidden] {
  display: none;
}
.structuredbreadcrumb li + li::before {
  content: "/";
  padding: 0 8px;
  display: inline-block;
}
.structuredbreadcrumb li > span {
  font-weight: 600;
}
.structuredbreadcrumb a {
  text-decoration: none;
  text-underline-position: under;
  color: inherit;
}
.structuredbreadcrumb a:hover {
  text-decoration: underline;
}

.structuredbreadcrumb-wrapper.bglightblue .structuredbreadcrumb {
  margin-bottom: 0;
}

/* sharepage */
.sharepage {
  margin-top: 30px;
}
@media (max-width: 600px) {
  .sharepage {
    margin-top: 5vw;
  }
}
.sharepage__title {
  font-size: 14px;
  color: #727272;
  margin-bottom: 5px;
}
.sharepage__options {
  display: flex;
  align-items: flex-start;
}
.sharepage button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: var(--color-black);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms;
  font-size: 17px;
}
.sharepage button:hover {
  opacity: 0.85;
}
.sharepage button + button {
  margin-left: 10px;
}

/* contentlist */
.pagecontentlist {
  margin-bottom: 50px;
}

.contentlist {
  list-style-type: none;
  margin: 30px 0 15px;
  padding: 0;
}
.contentlist a {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: inherit;
  text-decoration: none;
  position: relative;
}
.contentlist a::before {
  content: "";
  display: block;
  position: absolute;
  top: -15px;
  right: -30px;
  bottom: -15px;
  left: -30px;
  background-color: var(--color-blue-light);
  opacity: 0;
  transition: opacity 0.2s;
  z-index: -1;
  border-radius: 8px;
}
@media (max-width: 750px) {
  .contentlist a::before {
    right: -20px;
    left: -20px;
  }
}
.contentlist a:hover::before {
  opacity: 1;
}
.contentlist__item + .contentlist__item {
  margin-top: 30px;
}
.contentlist__item__text > * {
  display: block;
  line-height: 28px;
}
.contentlist__item__text__title {
  font-family: var(--font-heading);
  font-weight: bold;
  font-size: clamo(18px, 2.6vw, 26px);
  line-height: 140%;
}
.contentlist__item__text__description {
  padding-top: 5px;
}
.contentlist__item__image {
  display: block;
  margin-left: auto;
  flex: none;
  width: 180px;
  padding-left: 20px;
}
@media (max-width: 600px) {
  .contentlist__item a::before {
    right: -20px;
    left: -20px;
  }
  .contentlist__item__image {
    display: none;
  }
}

.styledscrollbar-x,
.styledscrollbar-y {
  overflow: hidden;
  scroll-behavior: smooth;
  /* firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--color-gray) white;
  /* webkit */
}
.styledscrollbar-x::-webkit-scrollbar,
.styledscrollbar-y::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-track-piece,
.styledscrollbar-y::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-button, .styledscrollbar-x::-webkit-scrollbar-corner, .styledscrollbar-x::-webkit-resizer,
.styledscrollbar-y::-webkit-scrollbar-button,
.styledscrollbar-y::-webkit-scrollbar-corner,
.styledscrollbar-y::-webkit-resizer {
  display: none;
}
.styledscrollbar-x::-webkit-scrollbar-thumb,
.styledscrollbar-y::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 7px 7px var(--color-gray);
}
.styledscrollbar-x::-webkit-scrollbar-track,
.styledscrollbar-y::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px 7px #fff;
}

.styledscrollbar-x {
  overflow-x: auto;
}

.styledscrollbar-y {
  overflow-y: auto;
}