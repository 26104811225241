.widget-twocol {
  /*
    &:not(.basegrid__wide)
    {
      @media(max-width: 1000px)
      {
        grid-area: auto / wide-start / auto / wide-end;
      }
    }

    @media(max-width: $maxwidth_mobile)
    {
      grid-area: auto / xwide-start / auto / xwide-end !important;
    }
    */
}
.widget-twocol__columns {
  display: flex;
  justify-content: space-between;
}
.widget-twocol__columns__col {
  flex: 0 1 50%;
  max-width: calc(50% - 15px);
}
.widget-twocol__columns__col > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 750px) {
  .widget-twocol__columns {
    display: block;
  }
  .widget-twocol__columns__col {
    max-width: 100%;
  }
  .widget-twocol__columns__col + .widget-twocol__columns__col {
    margin-top: 20px;
  }
}